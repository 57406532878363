<template>
  <section>
    <b-sidebar
      id="edit-surveys-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="edit-surveys-sidebar"
      :aria-expanded="openSideBar"
      width="50%"
    >
      <validation-observer
        ref="observer"
        v-slot="{ pristine, invalid, handleSubmit, errors }"
      >
        <b-form @submit.prevent="handleSubmit(onUpdate)">
          <b-card>
            <b-form-radio-group
              id="radio-group"
              v-model="survey.survey_type_id"
            >
              <b-row class="mb-2">
                <b-col sm="6">
                  <div :class="survey.survey_type_id === surveyTypes.MATCH_BASE? 'text-center active': 'text-center'">
                    <h4 class="mb-1">
                      <b>Survey for matched participants</b>
                    </h4>
                    <p class="mb-1">
                      Matched participants receive the survey
                    </p>
                    <b-form-radio
                      :value="surveyTypes.MATCH_BASE"
                      aria-describedby="Mentee role"
                      name="mentee-role"
                      class=""
                    />
                  </div>
                </b-col>
                <b-col sm="6">
                  <div :class="survey.survey_type_id === surveyTypes.PARTICIPANT? 'text-center active': 'text-center'">
                    <h4 class="mb-1">
                      <b>Survey for participants</b>
                    </h4>
                    <p class="mb-1">
                      Selected recipients receive the survey
                    </p>
                    <b-form-radio
                      :value="surveyTypes.PARTICIPANT"
                      aria-describedby="Mentee role"
                      name="mentee-role"
                      class=""
                    />
                  </div>
                </b-col>
              </b-row>
            </b-form-radio-group>
            <!-- Recipient -->
            <div
              v-if="inProgramDetail && showRecipient"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                rules="required"
                vid="recipient"
                name="Groups"
              >
                <b-form-group
                  label="Groups"
                  label-for="recipient"
                  class="mb-0"
                >
                  <v-select
                    v-model="recipientGroup"
                    label="name"
                    :options="programUserGroups"
                    :loading="isLoadingGroups"
                    placeholder="Select a Group"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    <template #option="{ name, type }">
                      <b-row>
                        <b-col sm="12">
                          <span class="mr-1"> {{ name }}</span>
                          <b-badge
                            variant="secondary"
                          >
                            {{ type }}
                          </b-badge>
                        </b-col>
                      </b-row>
                    </template>

                    <template #selected-option="{ avatar, name }">
                      <b-avatar
                        size="sm"
                        class="border border-white"
                        :src="avatar"
                      />
                      <span class="ml-50"> {{ name }}</span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <user-roles-select
              v-if="survey && !isFetching"
              list-type="participant"
              :value="survey.roles"
              :custom-label="'Participant Role'"
              @update="updateUserRole"
            />

            
            <div>
              <b-row class="mt-2">
                <b-col md="12">
                  <validation-provider
                    v-slot="validationContext"
                    :rules="{ required: true }"
                    name="Title"
                  >
                    <b-form-group 
                      label="Title"
                      label-for="title"
                    >
                      <b-form-input
                        v-model="surveyTitle"
                        type="text"
                        placeholder="Title"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            
              <b-row class="mt-1">
                <b-col md="12">
                  <validation-provider
                    v-slot="validationContext"
                    :rules="{ required: true, min: 10 }"
                    name="Description"
                  >
                    <b-form-group 
                      label="Description"
                      label-for="description"
                    >
                      <b-form-textarea
                        v-model="surveyDescription"
                        placeholder="Description"
                        maxlength="255"
                        rows="3"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>

            <div
              v-if="survey && !isFetching"
              slot="footer"
            >
              <b-row>
                <b-col md="3">
                  <label class="mt-1">Show</label>
                </b-col>
              </b-row>
              <b-row>
                <!-- When -->
                <b-col
                  md="12"
                  lg="4"
                >
                  <validation-provider
                    v-slot="validationContext"
                    rules="required"
                    vid="show-when"
                    name="When"
                  >
                    <b-form-group label-for="show">
                      <v-select
                        id="show-when"
                        v-model="happenWhen"
                        label="name"
                        placeholder="When"
                        :options="eventTriggersDisplay"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Event -->
                <b-col
                  md="12"
                  lg="8"
                >
                  <validation-provider
                    v-slot="validationContext"
                    rules="required"
                    vid="show-actionable-event"
                    name="Event"
                  >
                    <b-form-group label-for="show">
                      <aom-events-select
                        id="show-actionable-event"
                        v-model="actionableEvent"
                        :options="eventsDisplayComputed"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <b-row v-if="showCustomDateField">
                    <b-col md="12">
                      <aom-date-picker
                        v-model="scheduledDate"
                        :min="minCustomDate"
                      />
                    </b-col>
                  </b-row>
                </b-col>

                <!-- Period -->
                <b-col
                  v-if="showPeriodField"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    v-slot="validationContext"
                    vid="time"
                    :rules="{ required: true }"
                    name="Period"
                  >
                    <b-form-group label-for="show">
                      <v-select
                        id="time"
                        v-model="period"
                        label="#"
                        placeholder="Period"
                        :options="periodList"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Duration -->
                <b-col
                  v-if="showDurationField"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    v-slot="validationContext"
                    vid="duration"
                    :rules="{ required: true }"
                    name="Duration"
                  >
                    <b-form-group label-for="show">
                      <v-select
                        id="duration"
                        v-model="duration"
                        label="name"
                        placeholder="Duration"
                        :options="durationUnitsDisplay"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row v-if="showMatchAlert" class="mt-1">
                <b-col>
                  <b-alert
                    show
                    variant="danger"
                    class="p-1"
                  >
                    <span class="match-alert">Matches published after this date will not receive the survey</span>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col
                  sm="6"
                  md="4"
                  class="mb-1"
                >
                  <b-button
                    block
                    type="reset"
                    variant="outline-dark"
                    @click="clearForm"
                  >
                    Cancel
                  </b-button>
                </b-col>
                <b-col
                  sm="6"
                  md="4"
                  class="mb-1"
                >
                  <b-button
                    block
                    type="submit"
                    variant="primary"
                  >
                    <b-spinner
                      v-if="isUpdating"
                      small
                    />
                    <span v-if="isUpdating"> Updating...</span>
                    <span v-else>Submit</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BSidebar,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BBadge,
  BAvatar,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
  BAlert
} from "bootstrap-vue";
import AomLanguages from "@aom-core/AomLanguages";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import ApplicationTitleDesc from "../applications/ApplicationTitleDesc.vue";
import formsService from "@/services/formsService";
import QuestionSkeletonLoader from "../../questions/QuestionSkeletonLoader.vue";
import { formTypes } from "@models/formTypes";
import UserRolesSelect from "@/@aom-core/UserRolesSelect.vue";
import { RolesClass } from "@models/userRolesClass";
import vSelect from "vue-select";
import { getValidationState, convertStringToDateIgnoreTimezone } from "@/libs/utils";
import {
  actions,
  eventTriggers,
  eventTriggersDisplay,
  durationUnitsDisplay,
  actionableEvent as defaultEvents,
  locales
} from '@models';
import { FormClass, FormTranslations } from '@models/formsClass';
import { groupsService } from "@/services";
//eslint-disable-next-line
import { required } from "@validations";
import useActionableEvent from "@/views/apps/useActionableEvent.js";
import AomEventsSelect from '@/@aom-core/AomEventsSelect.vue';
import AomDatePicker from "@aom-core/AomDatePicker";
import { surveyTypes } from "@/models/surveyTypes";
import { locale } from "core-js";

export default {
  name: "EditSurveySideBar",
  components: {
    BAlert,
    BFormInput,
    BFormTextarea, 
    BCard,
    BRow,
    BCol,
    BSidebar,
    AomLanguages,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver,
    ApplicationTitleDesc,
    QuestionSkeletonLoader,
    ValidationProvider,
    UserRolesSelect,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    BBadge,
    BAvatar,
    AomEventsSelect,
    AomDatePicker,
    BFormRadio,
    BFormRadioGroup,
  },
  props: {
    isProgramTemplate: {
      type: Boolean,
      default: false,
      required: false,
    },
    showRecipient: {
      type: Boolean,
      default: true,
    },
    inProgramDetail: {
      type: Boolean
    },
    title: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      default: 0,
    },
  },
  data(vm) {
    return {
      isUpdating: false,
      openSideBar: vm.open,
      survey: {},
      isFetching: false,
      eventTriggersDisplay,
      durationUnitsDisplay,
      periodList: Array.from({length: 100}, (_, i) => i + 1),
      isLoadingGroups: false,
      recipientGroup: null,
      programUserGroups: [],
      scheduledAction: null,
      surveyTitle: null,
      surveyDescription: null
    };
  },
  computed: {
    showMatchAlert() {
      let selectedKeyDateOrCustomDate = false;
      if (this.survey.scheduled_actions?.length) {
        if (this.survey.scheduled_actions[0].actionable_event_id) {
          selectedKeyDateOrCustomDate = [defaultEvents.MATCH_START, defaultEvents.MATCH_COMPLETED].indexOf(this.survey.scheduled_actions[0].actionable_event_id) === -1;
        } else if (this.survey.scheduled_actions[0].absolute_date) {
          selectedKeyDateOrCustomDate = true;
        }
      } 
      return this.survey.survey_type_id === surveyTypes.MATCH_BASE && selectedKeyDateOrCustomDate;
    },
    eventsDisplayComputed () {
      let eventsDisplayClone = this.eventsDisplay;
      if (this.survey.survey_type_id === surveyTypes.MATCH_BASE) {
        eventsDisplayClone = eventsDisplayClone.filter(event => event.id != defaultEvents.ON_REGISTRATION)
      }
      return eventsDisplayClone;
    },
    sideBarTitle() {
      if (this.survey) {
        const defaultTitle = this.getSurveyTitle();
        return `Edit ${defaultTitle}`;
      }
      return 'Edit Survey';
    }
  },
  watch: {
    open() {
      this.openSideBar = this.open;
    },
    openSideBar(n) {
      this.$emit("toggleSideBar", n);
    },
    surveyId: {
      async handler() {
        if (this.surveyId !== 0) {
          if (this.inProgramDetail) {
            await this.getGroups();
          }
          await this.getSurveyById();
        }
      },
      immediate: true,
    },
    happenWhen() {
      this.survey.scheduled_actions = [{ action_id: actions.SHOW, status_id: this.survey.scheduled_actions[0].status_id }];
    },
    actionableEvent(n) {
      if(n) {
        this.updateScheduledEvent(n);
      }
    },
    duration(n) {
      if(n) {
        this.updateScheduledDuration(n);
      }
    },
    period(n) {
      if(n) {
        this.updateScheduledPeriod(n);
      }
    },
    scheduledDate(n) {
      if(n) {
        this.updateScheduledAbsoluteDate(n);
      }
    },
  },
  setup(props) {
    const {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      minCustomDate
    } = useActionableEvent(props.inProgramDetail);

    return {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      getValidationState,
      minCustomDate,
      surveyTypes
    };
  },
  methods: {
    async resolveService() {
      const programId = this.$route.params.id;
      if (this.isProgramTemplate) {
        return groupsService.getListTemplates(programId);
      }
      return groupsService.getList(programId);
    },
    async getGroups() {
      try {
        this.isLoadingGroups = true;
        const response = await this.resolveService();
        const {items} = response.data;
        this.programUserGroups = items.filter(group => !group.is_champion).map(i => ({id: i.id, name: i.name, type: i.type.name}));
      } catch (e) {
        this.$toast(makeErrorToast("Groups list not loaded."));
      } finally {
        this.isLoadingGroups = false;
      }
    },
    updateUserRole(role) {
      if (role.length > 0) {
        const targetRoles = role.map(
          r => new RolesClass({ id: r.id, name: r.name })
        );
        this.survey = {
          ...this.survey,
          roles: targetRoles,
        };
      }
    },
    updateScheduledEvent(e) {
      const { id } = e;
      if (id) {
        if (this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({
            ...action,
            action_id: actions.SHOW,
            actionable_event_id: id,
            absolute_date: null
          }));
          this.survey = {
            ...this.survey,
            scheduled_actions: update,
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [
              {
                action_id: actions.SHOW,
                actionable_event_id: id,
              },
            ],
          };
        }
      }
    },
    updateScheduledDuration(d) {
      const { id } = d;
      if (id) {
        if (this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({
            ...action,
            relative_unit_id: id,
          }));
          this.survey = {
            ...this.survey,
            scheduled_actions: update,
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [
              {
                relative_unit_id: id,
              },
            ],
          };
        }
      }
    },
    updateScheduledPeriod(p) {
      if (p) {
        let newP = 0;
        // Convert relative_value
        if (this.happenWhen) {
          switch (this.happenWhen.id) {
            case eventTriggers.BEFORE:
              newP = -p;
              break;
            case eventTriggers.ON:
              newP = 0;
              break;
            case eventTriggers.AFTER:
              newP = p;
              break;
          }
        }

        if (this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({
            ...action,
            relative_value: newP,
          }));
          this.survey = {
            ...this.survey,
            scheduled_actions: update,
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [
              {
                relative_value: newP,
              },
            ],
          };
        }
      }
    },
    updateScheduledAbsoluteDate(date) {
      if (date) {
        if (this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({
            ...action,
            actionable_event_id: null,
            absolute_date: date,
          }));
          this.survey = {
            ...this.survey,
            scheduled_actions: update,
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [
              {
                actionable_event_id: null,
                absolute_date: date,
              },
            ],
          };
        }
      }
    },
    closeSideBar() {
      this.openSideBar = false;
      this.$emit("close");
    },
    isTabDataDirty(errors) {
      let tabErrorIndex = [];
      for (const [key, error] of Object.entries(errors)) {
        const regex = /\d+/g;
        if (error.length > 0) {
          const tabIndex = key.match(regex);
          if (Array.isArray(tabIndex)) {
            tabErrorIndex.push(tabIndex[0]);
          }
        }
      }
      return tabErrorIndex;
    },
    clearForm() {
      this.surveyTitle = "";
      this.surveyDescription = "";
      this.survey = new FormClass({
        roles: [],
        translations: [],
        scheduled_actions: [{ action_id: actions.SHOW }],
        type_id: formTypes.SURVEY,
      });
      this.happenWhen = undefined;
      this.actionableEvent = undefined;
      this.period = undefined;
      this.duration = undefined;
      this.scheduledDate = undefined;
      this.closeSideBar();
    },
    async getSurveyById() {
      try {
        this.isFetching = true;
        let response = null;
        if (this.inProgramDetail) {
          response = await formsService.getProgramSurvey(this.$route.params.id, this.surveyId);
        } else {
          response = await formsService.getSurvey(this.surveyId);
        }
        const { data } = response;
        this.survey = data;
        this.surveyTitle = this.getSurveyTitle();
        this.surveyDescription = this.getSurveyDescription();
        if (this.survey.group_id) {
          this.recipientGroup = this.programUserGroups.find(g => g.id === this.survey.group_id) ?? null;
        }
        if (this.survey.scheduled_actions.length > 0) {
          const scheduledAction = this.survey.scheduled_actions[0];
          let newScheduleAction = null;
          this.scheduledAction = scheduledAction;

          if (!scheduledAction.relative_value) {
            this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
          } else {
            if (scheduledAction.relative_value < 0) {
              this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.BEFORE);
            } else {
              this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.AFTER);
            }
          }

          this.$nextTick(() => {
            this.actionableEvent = scheduledAction.absolute_date
              ? { id: defaultEvents.CUSTOM_DATE, name: 'Custom Date' }
              : this.eventsDisplay.filter(a => a.id === scheduledAction.actionable_event_id).shift();
            this.period = Math.abs(scheduledAction.relative_value);
            this.duration = durationUnitsDisplay.filter(d => d.id === scheduledAction.relative_unit_id)[0];
            this.scheduledDate = scheduledAction.absolute_date ? convertStringToDateIgnoreTimezone(scheduledAction.absolute_date) : undefined;
          });

          if (scheduledAction.absolute_date) {
            newScheduleAction = {
              action_id: actions.SHOW,
              absolute_date: scheduledAction.absolute_date ? convertStringToDateIgnoreTimezone(scheduledAction.absolute_date) : undefined,
              relative_value: scheduledAction.relative_value,
              relative_unit_id: scheduledAction.relative_unit_id
            };
          } else {
            newScheduleAction = {
              action_id: actions.SHOW,
              actionable_event_id: scheduledAction.actionable_event_id,
              relative_value: scheduledAction.relative_value,
              relative_unit_id: scheduledAction.relative_unit_id
            };
          }
          // Re-assign scheduled_actions
          if (newScheduleAction) {
            this.survey = {
              ...this.survey,
              scheduled_actions: [newScheduleAction]
            };
          }
        }
      } catch (e) {
        // this.$log.error(e);
        this.$toast(makeErrorToast("Error Fetching Survey."));
      } finally {
        this.isFetching = false;
      }
    },
    async onUpdate() {
      try {
        this.isUpdating = true;
        let data = this.survey;
        data = {
          ...data,
          translations: [
            {
              locale_id: locales.EN,
              title: this.surveyTitle,
              description: this.surveyDescription
            }
          ]
        };
        if (!this.inProgramDetail) {
          delete data.group_id;
        }
        if (this.scheduledAction) {
          data.scheduled_actions = data.scheduled_actions.map(action => ({
            ...action,
            status_id: this.scheduledAction.status_id
          }));
        }
        if (this.inProgramDetail) {
          if (this.recipientGroup?.id) {
            data.group_id = this.recipientGroup.id;
          } else {
            delete data.group_id;
          }
           await formsService.updateProgramSurvey(
            this.$route.params.id,
            this.surveyId,
            data
          );
        } else {
          await formsService.updateSurvey(
            this.surveyId,
            data
          );
        }

        this.$toast(makeSuccessToast("Survey updated successfully."));
        this.$emit("surveyUpdated");
        this.clearForm();
      } catch (e) {
        console.log(e);
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        }
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    getSurveyTitle() {
      if(this.survey) {
        const trans = this.survey.translations?.filter(trans => (trans.locale_id === locales.EN));
        if(trans?.length > 0) {
          return trans[0].title;
        }
        return '';
      }
    },
    getSurveyDescription() {
      if(this.survey) {
        const trans = this.survey.translations?.filter(trans => (trans.locale_id === locales.EN));
        if(trans?.length > 0) {
          return trans[0].description;
        }
        return '';
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$sidebar-header-text-color: #808080;

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  background: #f3f3f3;
  color: $sidebar-header-text-color;

  #edit-surveys-sidebar___title__ {
    font-size: 0.8em;
    flex: 2;
  }
}

#radio-group .text-center {
  border: 1px solid #d8d6de;
  border-radius: 10px;
  padding: 19px 0px;
  &.active {
    border: 2px solid var(--aom-color-secondary);
  }
}
</style>